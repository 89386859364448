import localforage from "localforage";

/**
 * Set Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const  setAuthData = async (keyName, authData) => {
  try {
    await setItem(keyName, authData);
    const value = await getItem(keyName);
    return JSON.parse(value);
  } catch (err) {
    return authData;
  }
};

/**
 * Get Auth Data
 *
 * @param keyName
 * @param authData
 * @returns AuthObject
 */
export const getAuthData = async () => {
  return await getItem("authAdminInfo");
};

/**
 * Agrega un item al store
 *
 * @param key
 * @param value
 */
export const setItem = async (key, value) => {
  await localforage.setItem(key, value).catch((error) => {
    throw new Error("Error en almacenamiento de informacion");
  });
};

/**
 * busca un item al store
 *
 * @param key
 */
export const getItem = async (key) => {
  return await localforage.getItem(key).catch((error) => {
    throw new Error("Error en recuperacion de informacion");
  });
};

/**
 * Elimina un item al store
 *
 * @param key
 */
export const removeItem = async (key) => {
  await localforage.removeItem(key).catch((error) => {
    throw new Error("Error en eliminar la informacion");
  });
};
