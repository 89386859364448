import { useEffect, useState } from "react";

export const getErrorMessageFromError = (error) => {
    if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
    } else {
        return "Error desconocido. Por favor, inténtalo de nuevo más tarde.";
    }
};

const ErrorMessage = ({ error }) => {
    const [message, setMessage] = useState(null);

    useEffect(() => {
        if (error) {
            const errorMessage = getErrorMessageFromError(error);
            setMessage(errorMessage);
        }
    }, [error]);

    return message ? <p>{message}</p> : null;
};

export default ErrorMessage;
