import { createTheme } from "@mui/material/styles";
import { createContext, useMemo, useState } from "react";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
      primary: {
        100: "#d0d1d5",
        200: "#a1a4ab",
        300: "#727681",
        400: "#1F2A40",
        500: "#141b2d",
        600: "#101624",
        700: "#0c101b",
        800: "#080b12",
        900: "#040509",
      },
      secondary: {
        100: "#ebf8ff",
        200: "#bee3f8",
        300: "#90cdf4",
        400: "#63b3ed",
        500: "#4299e1",
        600: "#3182ce",
        700: "#2b6cb0",
        800: "#2c5282",
        900: "#2a4365",
      },  
      grey: {
        100: "#e0e0e0",
        200: "#c2c2c2",
        300: "#a3a3a3",
        400: "#858585",
        500: "#666666",
        600: "#525252",
        700: "#3d3d3d",
        800: "#292929",
        900: "#141414",
      },
      green: {
        100: "#f0fff4",
        200: "#c6f6d5",
        300: "#9ae6b4",
        400: "#68d391",
        500: "#48bb78",
        600: "#38a169",
        700: "#2f855a",
        800: "#276749",
        900: "#22543d",
      },
      red: {
        100: "#fff5f5",
        200: "#fed7d7",
        300: "#feb2b2",
        400: "#fc8181",
        500: "#f56565",
        600: "#e53e3e",
        700: "#c53030",
        800: "#9b2c2c",
        900: "#742a2a",
      },   
    }
    : {
      primary: {
        100: "#FFEDC7",
        200: "#fee59a",
        300: "#fed867",
        400: "#fecb34", 
        500: "#febf04", //principal
        600: "#e4ac01",
        700: "#b28501",
        800: "#7f5f01",
        900: "#4c3900",
      },  
      secondary: {
        100: "#d1e7fa",
        200: "#a3cff5",
        300: "#75b7f0",
        400: "#469fec",
        500: "#1886e7",
        600: "#1576cd", // principal
        700: "#115ea2",
        800: "#0c4373",
        900: "#072845",
      },
      grey: {
        100: "#ffffff",
        200: "#f9f9f9", // principal
        300: "#bfbfbf",
        400: "#999999",
        500: "#808080",
        600: "#666666",
        700: "#404040",
        800: "#1a1a1a",
        900: "#000000",
      },
      green: {
        100: "#e0ffce",
        200: "#bffb9d",
        300: "#a0fa6b",
        400: "#80f83a",
        500: "#60f609",
        600: "#56dd08",
        700: "#49B707", //principal
        800: "#3a9405",
        900: "#266204",
      },
      red: {
        100: "#fdcecf",
        200: "#fb9da0",
        300: "#fa6b70",
        400: "#f83a40",
        500: "#f60911",
        600: "#b7070d",
        700: "#94050a",
        800: "#620407",
        900: "#310203",
      },   
      orange: {
        100: "#ffedcc",
        200: "#fedb9a",
        300: "#fec967",
        400: "#feb734",
        500: "#fea707",
        600: "#cb8401",
        700: "#986301",
        800: "#654201",
        900: "#332100",
      }, 
    }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.secondary[600],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: colors.primary[500],
          },
        }
        : {
          // palette values for light mode
          primary: {
            main: colors.primary[500],
          },
          secondary: {
            main: colors.secondary[600],
          },
          neutral: {
            dark: colors.grey[700],
            main: colors.grey[500],
            light: colors.grey[100],
          },
          background: {
            default: colors.grey[200],
          },
        }),
    },
    typography: {
      fontFamily: ["Quicksand", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Quicksand", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none', // Desactiva el uppercase para todos los botones
          },
        },
      },
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => { },
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};
