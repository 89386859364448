import React, { createContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { ClientDto } from "../../model/ClientDto";
import { ClientDelete, ClientListRequest, ClientSaveRequest, ClientUpdatedRequest } from "../../services/client/ClientService";
import { useSidebarData } from "../global/SidebarDataContext";

const ClientsDataContext = createContext();

const ClientsDataContextProvider = ({ children }) => {
    const [listClients, setListClients] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [clientIdToDelete, setClientIdToDelete] = useState('');
    const [formData, setFormData] = useState({});
    const [company, setCompany] = useState(' ')
    const [searchInput, setSearchInput] = useState("");

    const {
        setSuccessMsg,
        setErrorMsg,
        setLoading,
        messageUser,
        authenticate,
        authInfo
    } = useApp();

    useEffect(()=>{
    },[authInfo])

    const { personWithNewMessage , processedNewMessagesCount} = useSidebarData();

    const navigate = useNavigate();

    const handleCancel = async () => {
        navigate(-1);
    };

    useEffect(() => {

        if (personWithNewMessage) {
            // console.log(personWithNewMessage);
            // Ordenar la lista de contribuyentes cuando haya cambios en personWithNewMessage
            const sortedClients = listClients.slice().sort((a, b) => {
                const aHasNewMessage = personWithNewMessage.some(person => person.client.id === a.id);
                const bHasNewMessage = personWithNewMessage.some(person => person.client.id === b.id);

                // Si a tiene mensaje nuevo y b no, a debe aparecer antes en la lista
                if (aHasNewMessage && !bHasNewMessage) return -1;
                // Si b tiene mensaje nuevo y a no, b debe aparecer antes en la lista
                if (!aHasNewMessage && bHasNewMessage) return 1;
                // Si ambos tienen o no tienen mensaje nuevo, no se cambia el orden
                return 0;
            });
            setListClients(sortedClients);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personWithNewMessage,processedNewMessagesCount]);

    // useEffect(() => {
    //     loadClients(0)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [setCompany, company]);

    const loadClients = async (page) => {
        try {
            let responseClientsList = await ClientListRequest( page, 100, company,authInfo.id, authInfo.userRole.id, searchInput);

            const clients = responseClientsList.content.map(clientData => {
                return new ClientDto(
                    clientData.id,
                    clientData.name,
                    clientData.firstSurname,
                    clientData.secondSurname,
                    clientData.cellPhone,
                    clientData.email,
                    clientData.prospect,
                    clientData.jobTitle,
                    clientData.company,
                    clientData.company_name,
                    clientData.status
                );
            });
            setListClients(clients);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage)
            setLoading(false);
        }
    };

    const onClickMarkChatUnread = (params) => {
        if (messageUser.some(item => item.client.id === params.row.id)) {
            navigate('/dashboard/whatsapp', { state: params.row });
        }
    };

    const openDeleteConfirmation = (clientId) => {
        setClientIdToDelete(clientId);
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setClientIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (clientIdToDelete) {
            await handleDelete(clientIdToDelete);
        }
    };

    const handleFormSubmit = async (values) => {
        try {
            const input = {
                ...values
            };
            setLoading(true);
            if (values.id) {
                await ClientUpdatedRequest(input);
                setSuccessMsg("Editado con éxito");
            } else {
                await ClientSaveRequest(input);
                setSuccessMsg("Creado con éxito");
            }
            loadClients(0);
            setLoading(false);
            handleCancel();
        } catch (error) {
            console.error(error);
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    const handleDelete = async (clientId) => {
        try {
            setLoading(true)
            await ClientDelete(clientId);
            setSuccessMsg("Eliminado con éxito")
            loadClients(0);
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    };

    // useEffect(() => {
    //     if (authenticate) {
    //         // console.log('load');
    //         Promise.all([loadClients(0)]);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    return (
        <ClientsDataContext.Provider value={{
            listClients,
            isEditing,
            formData,
            loadClients,
            setIsEditing,
            showDeleteConfirmation,
            clientIdToDelete,
            onClickMarkChatUnread,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            handleDelete,
            handleFormSubmit,
            handleCancel,
            setFormData,
            company,
            setCompany,
            searchInput,
            setSearchInput
        }}>
            {children}
        </ClientsDataContext.Provider>
    );
};

export { ClientsDataContext, ClientsDataContextProvider };
