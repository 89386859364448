
import { createContext, useContext, useEffect, useState } from "react";
import iconNotification from '../../assets/notification/icon.svg';
import notificationSound from '../../assets/notification/notification.mp3';
import { useApp } from "../../hooks/useApp";
import { notificationNewCountMessage } from "../../services/whatsapp/WhatsAppService";

import "react-pro-sidebar/dist/css/styles.css";

const SidebarDataContext = createContext();

const SidebarDataContextProvider = ({ children }) => {

    const [selectedMenuItem, setSelectedMenuItem] = useState("Dashboard");
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [newMessageClient, setNewMessageClient] = useState(false);
    const [selected, setSelected] = useState("Dashboard");
    const [processedNewMessagesCount, setProcessedNewMessagesCount] = useState(0);
    const [personWithNewMessage, setPersonWithNewMessage] = useState()
    const [prevMessagesCount, setPrevMessagesCount] = useState({});

    const {
        setMessageUser,
        setNewMessageTimeout,
        authInfo
    } = useApp();

    const playNotificationSound = () => {
        const audio = new Audio(notificationSound);
        audio.play();
    };

    useEffect(() => {

        const updateFavicon = () => {
            const favicon = document.querySelector('link[rel="icon"]');
            favicon.href = iconNotification;

            if (processedNewMessagesCount > 0) {
                favicon.href = '';
            }
        };

        updateFavicon();

        const interval = setInterval(obtenerMensajes, 10000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prevMessagesCount]);

    const obtenerMensajes = async () => {
        try {
            if(authInfo && authInfo.id && authInfo.userRole.id){
                const response = await notificationNewCountMessage(authInfo.id, authInfo.userRole.id);
                if (response && response.content.length > 0) {
                    let playSound = false;
                    setNewMessageTimeout(response.content);
                    // Verificar si hay nuevos mensajes para contribuyentes prospecto y no prospecto
                    const hasNewClientMessages = response.content.some(item => item?.countNewMessages > 0);
                    // Actualizar el estado de nuevos mensajes para contribuyentes prospecto y no prospecto
                    setNewMessageClient(hasNewClientMessages);

                    // Actualizar el estado de mensajes de agente con la respuesta completa
                    setMessageUser(response.content);

                    response.content.forEach(message => {
                        const userId = message.client.id;
                        const newCount = message.countNewMessages;
                        const prevCount = prevMessagesCount[userId] || 0;
                        if (newCount > prevCount) {
                            playSound = true;
                            // Actualizar el estado previo para este agente
                            setPrevMessagesCount(prev => ({
                                ...prev,
                                [userId]: newCount
                            }));
                        }
                    });

                    if (playSound) {
                        playNotificationSound();
                        const favicon = document.querySelector('link[rel="icon"]');
                        favicon.href = iconNotification;
                        localStorage.setItem('hasSeenNewMessages', 'true');
                    }

                    setProcessedNewMessagesCount(response.content.length);
                    setPersonWithNewMessage(response.content);
                } else {
                    // Si no hay nuevos mensajes, actualizar el estado correspondiente y eliminar el ícono de notificación
                    setProcessedNewMessagesCount(0);
                    const favicon = document.querySelector('link[rel="icon"]');
                    favicon.href = '';
                    setNewMessageClient(false);
                    setMessageUser([]);
                }
            }
        } catch (error) {
            console.error('Error al obtener mensajes:', error);
        }
    };

    return (
        <SidebarDataContext.Provider
            value={{
                selectedMenuItem,
                setSelectedMenuItem,
                isCollapsed,
                setIsCollapsed,
                newMessageClient,
                setNewMessageClient,
                processedNewMessagesCount,
                setProcessedNewMessagesCount,
                selected,
                setSelected,
                personWithNewMessage,
            }}
        >
            {children}
        </SidebarDataContext.Provider>
    );
};

// Hook personalizado para usar el contexto
const useSidebarData = () => {
    return useContext(SidebarDataContext);
};

export { SidebarDataContext, SidebarDataContextProvider, useSidebarData };
