import { CONSTANT } from "../../utils/Constant";
import { privateFetch } from "../../utils/CustomFetch";

export const saveMessageTemplateRequest = async (data) => {
    try {
        const response = await privateFetch
            .post('maintenance/message-template', data, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        return response.data;

    } catch (error) {

        throw error;
    }
};

export const updateMessageTemplateRequest = async (data) => {
    
    try {
        // console.log(data);
        const response = await privateFetch
            .put(`maintenance/message-template/${data.id}`, data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        return response.data;
    } catch (error) {
        throw error;
    };
}

export const getMessageTemplateRequest = async (paramsRequest) => {
    let responseData = [];
    await privateFetch
        .get(`maintenance/message-template/findAll`, {
            headers: {
            ...CONSTANT.HEADER_TYPE,
            },
            params: paramsRequest
        })
      .then(async (response) => {
        responseData = await response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
    return responseData;
  };


export const deleteMessageTemplateRequest = async (id) => {
    try {
        const response = await privateFetch
            .delete(`maintenance/message-template/${id}`, {
                headers: {
                    ...CONSTANT.HEADER_TYPE,
                },
            });

        if (response.status === 200) {
            return 'Datos eliminados';
        } else {
            throw new Error("Error al eliminar.");
        }
    } catch (error) {
        throw error;
    }
};