export const CONSTANT = {
    /** DISPATCHER PREFIX */
    DISPATCHER_SECURITY_PREFIX: "/security",
    DISPATCHER_USER_PREFIX: "/users",
    DISPATCHER_MAINTENANCE_PREFIX: "/maintenance",

    /** LANGUAGE */
    DEFAULT_LANGUAGE_ES: "ES",
    DEFAULT_LANGUAGE_EN: "EN",

    /** TYPE MESSAGE */
    SUCCESS_MSG: 1,
    ERROR_MSG: 2,
    WARNING_MSG: 3,

    /** LABELS */
    LABEL_CRM: "CRM",
    LABEL_DASHBOARD: "Dashboard",
    /** Users **/
    LABEL_USERS: "Agentes",
    LABEL_NEW_USER: "Registrar agente",
    LABEL_EDIT_USER: "Editar agente",
    LABEL_SAVE_USER: "Guardar agente",
    LABEL_USERS_ACTIVE: "Agentes activos",
    /** Clients **/
    LABEL_CLIENT: "Contribuyente",
    LABEL_CLIENTS: "Contribuyentes",
    LABEL_NEW_CLIENT: "Registrar contribuyente",
    LABEL_EDIT_CLIENT: "Editar contribuyente",
    LABEL_SAVE_CLIENT: "Guardar contribuyente",
    LABEL_CONFIRMATION_DELETE_CLIENT: "¿Estás seguro de que deseas eliminar el contribuyente?",
    LABEL_NEW_MESSAGE: "Nuevo mensaje",

    /** Companies **/
    LABEL_COMPANIES: "Departamentos",
    LABEL_SELECT_COMPANY: "Seleccione una departamento",
    LABEL_NOT_COMPANY: "Sin departamento asignada",
    LABEL_COMPANY: "Departamento",
    LABEL_NEW_COMPANY: "Registrar departamento",
    LABEL_EDIT_COMPANY: "Editar departamento",
    LABEL_SAVE_COMPANY: "Guardar departamento",
    LABEL_CONFIRMATION_DELETE_COMPANY: "¿Estás seguro de que deseas eliminar el departamento?",
    LABEL_CONFIRMATION_TRANSFER_COMPANY: "¿Estás seguro de que deseas cambiar el departamento?",

    /** Whatsapp **/
    LABEL_WHATSAPP: "Whatsapp",
    LABEL_WHATSAPP_OUTCOMING: "Whatsapp saliente",
    LABEL_WHATSAPP_INCOMING: "Whatsapp entrante",
    LABEL_OUTCOMING: "saliente",
    LABEL_INCOMING: "entrante",


    

    /** UserData **/
    LABEL_FORM_PHONE: "Celular *",
    LABEL_FORM_NAME: "Nombre *",
    LABEL_FORM_EMAIL: "Correo electrónico *",
    LABEL_FORM_LAST_NAME: "Apellidos *",
    LABEL_FORM_PASSWORD: "Contraseña *",



    LABEL_NAME: "Nombre",
    LABEL_FIRSTSURNAME: "Primer Apellido *",
    LABEL_SECONDSURNAME: "Segundo Apellido *",
    LABEL_LAST_NAME: "Apellidos",
    LABEL_CELL_PHONE: "Celular",
    LABEL_CELL_PASSWORD: "Contraseña",
    LABEL_EMAIL: "Correo electrónico",
    LABEL_POSITION: "Cargo",
    LABEL_CANCEL: "Cancelar",
    LABEL_SAVE_EDIT: "Guardar cambios",
    LABEL_CONFIRMATION_DELETE_USER: "¿Estás seguro de que deseas eliminar el agente?",


    /** Templates **/
    LABEL_TEMPLATE_TWILIO: "Plantillas de Twilio",
    LABEL_TEMPLATE_QUICK_RESPONSE: "Respuestas rápidas",
    LABEL_TEMPLATE: "Plantilla",
    LABEL_NEW_TEMPLATE: "Registrar plantilla",
    LABEL_EDIT_TEMPLATE: "Editar plantilla",
    LABEL_SAVE_TEMPLATE: "Guardar plantilla",
    LABEL_CONFIRMATION_DELETE_TEMPLATE: "¿Estás seguro de que deseas eliminar la plantilla?",
    LABEL_ACTIVE_TEMPLATE: "Activar",
    COMMENT_TEMPLATE_REQUIRED: "Plantilla requerida",

    /****/
    LABEL_ACCION: "Acciones",
    LABEL_ID: "Id",
    LABEL_DELETE_TITLE_CONFIRMATION: "Confirmar eliminación",
    LABEL_NAME_REQUIRED: "El nombre es requerido",

    /** STATE */
    LABEL_ACTIVE: "Activo",
    LABEL_INACTIVE: "Inactivo",
    LABEL_PENDING: "Pendiente",

    /** COMMENTS */
    COMMENT_NAME_REQUIRED: "Nombre requerido",
    COMMENT_FIRSTSURNAME_REQUIRED: "Primer apellido es requerido",
    COMMENT_SECONDSURNAME_REQUIRED: "Segundo apellido es requerido",
    COMMENT_JOB_TITLE_REQUIRED: "Cargo requerido",
    COMMENT_COMPANY_REQUIRED: "Departamento requerida",
    COMMENT_CELL_PHONE_INVALID: "Celular inválido",
    COMMENT_CELL_PHONE_REQUIRED: "Celular requerido",
    COMMENT_EMAIL_INVALID: "Correo Electrónico inválido",
    COMMENT_EMAIL_REQUIRED: "Correo Electrónico requerido",
    COMMENT_DESCRIPTION_REQUIRED: "Descripción requerido",
    COMMENT_TYPE_REQUIRED: "Tipo requerido",


    /** SOCIALNETWORKTYPE */
    SOCIALTYPE_FACEBOOK: "FACEBOOK",
    SOCIALTYPE_INSTAGRAM: "INSTAGRAM",
    SOCIALTYPE_TWITTER: "TWITTER",
    SOCIALTYPE_LINKEDIN: "LINKEDIN",
    SOCIALTYPE_YOUTUBE: "YOUTUBE",

    /** HEADER TYPE */
    HEADER_TYPE: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    HEADER_TYPE2: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
    },
    HEADER_TYPE_FILE: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
    },
    AUTH_GRANTTYPE: "password",
}