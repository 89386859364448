import { createContext, useState } from "react";
import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { TemplateDto } from "../../model/TemplatesDto";
import { useNavigate } from "react-router-dom";
import { saveMessageTemplateRequest, updateMessageTemplateRequest, getMessageTemplateRequest, deleteMessageTemplateRequest } from "../../services/templates/MessageTemplatesService";

const TemplatesQuickResponseDataContext = createContext();

const TemplatesQuickResponseDataContextProvider = ({ children }) => {
    const [listTemplatesQuickResponse, setListTemplatesQuickResponse] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateIdToDelete, setTemplateIdToDelete] = useState(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const { setSuccessMsg,
        setErrorMsg,
        setLoading,
        messageUser,
        loading,
        authenticate,
        authInfo,
        isAdmin,
    } = useApp();

    const navigate = useNavigate();

    const handleCancel = async () => {
        navigate(-1);
    };

    const onClickActionEditButton = (params) => {
        setSelectedTemplate(params);
    };

    const handleFormSubmit = async (values) => {
        try {
            values.type='WHATSAPP_QUICK_RESPONSE';
            values.active = values.active ? 1 : 0; 
            const input = {
                ...values
            };
            setLoading(true)
            if (values.id) {
                await updateMessageTemplateRequest(input);
                setSuccessMsg("Editado con éxito");

            } else {
                await saveMessageTemplateRequest(input);
                setSuccessMsg("Creado con éxito")
            }
            loadTemplatesQuickResponse(null, null, 0);
            setLoading(false);
            handleCancel();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const loadTemplatesQuickResponse = async (criterio,active,page) => {
        try {
            const params = {
                criterio: criterio,
                type: 'WHATSAPP_QUICK_RESPONSE',
                active: active, 
                page: page,
                limit: 100
            }
            let responseTemplatesQuickResponseList = await getMessageTemplateRequest(params);

            const templatesQuickResponse = responseTemplatesQuickResponseList.content.map(templateData => {
                return new TemplateDto(
                    templateData.id,
                    templateData.template,
                    templateData.name,
                    templateData.type,
                    templateData.active,
                    templateData.authorized,
                );
            });
            setListTemplatesQuickResponse(templatesQuickResponse);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const openDeleteConfirmation = (templateId) => {
        setTemplateIdToDelete(templateId);
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setTemplateIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (templateIdToDelete) {
            await handleDelete(templateIdToDelete);
        }
    };

    const handleDelete = async (templateId) => {
        try {
            setLoading(true)
            await deleteMessageTemplateRequest(templateId);
            setSuccessMsg("Eliminado con éxito")
            loadTemplatesQuickResponse(null, null, 0);
            setLoading(false)
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    return (
        <TemplatesQuickResponseDataContext.Provider value={{
            listTemplatesQuickResponse,
            loadTemplatesQuickResponse,
            loading,
            messageUser,
            onClickActionEditButton,
            setSelectedTemplate,
            selectedTemplate,
            showDeleteConfirmation,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            handleFormSubmit,
            handleDelete,
            handleCancel
        }}>
            {children}
        </TemplatesQuickResponseDataContext.Provider>
    )
}

export { TemplatesQuickResponseDataContext, TemplatesQuickResponseDataContextProvider };