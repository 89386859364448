import React, { useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import "../../resources/styles/board.css";
import "../../resources/styles/boardpro.css";
import Sidebar from "../../pages/global/Sidebar";
import { useApp } from "../../hooks/useApp";
import { CssBaseline, Stack, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "../../theme";

/*****************************************************************************
 * @description componente template - board layout
 *
 ****************************************************************************/
const BoardLayout = (props) => {
    const {
        authenticate,
    } = useApp();
    const [isSidebar, setIsSidebar] = useState(true);
    const [theme, colorMode] = useMode();

    // validate pathname
    let { pathname } = useLocation();
    const specialRoutes = ["/board/authoritiesxrole"];
    const finalPathName = specialRoutes.find((r) => pathname.startsWith(r));
    if (finalPathName) {
        pathname = finalPathName;
    }

if(!authenticate){
    return <Navigate to="/"/>
}
    //set tittle
    document.title = "CRM";
    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app">
                        <Sidebar isSidebar={isSidebar} />
                        <main className="content">
                            <Stack
                                display="flex"
                                width="100%"
                                height="100%"
                            >
                                <Outlet />
                            </Stack>
                            <Footer/>
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
};
export default BoardLayout;
