import axios from "axios";
import { getItem } from "./LocalStorageManager";
import { jwtDecode } from 'jwt-decode';
import UrlBase from "../urls/Urls.js";

/**
 * Axion public 
 */
const publicFetch = axios.create({
  baseURL: UrlBase.base_url
});

/**
 * Axion private - internal endpoints 
 */
const privateFetch = axios.create({
  baseURL: UrlBase.base_url
});

/**
 * Axion private - interceptors
 */
// privateFetch.interceptors.request.use(
//   async (config) => {
//     // validate uri to send token
//     const { origin } = new URL(config.baseURL + config.url);
//     const allowedOrigins = [UrlBase.base_url];
//     const authInfo = await getItem("authInfo");
//     const decodeToken = jwtDecode(authInfo?.access_token);
//     const expiresAt = decodeToken.exp;
//     let valid = new Date().getTime() / 1000 < expiresAt;
//     if (allowedOrigins.includes(origin) && valid) {
//       config.headers.Authorization = `Bearer ${authInfo?.access_token}`;
//     }
//     return config;
//   }
// );

export { publicFetch, privateFetch};
