import { createContext, useState } from "react";
import { getErrorMessageFromError } from "../../components/ErrorMessage";
import { useApp } from "../../hooks/useApp";
import { CompanyDto } from "../../model/CompanyDto";
import { SVCompaniesRequest, SVCompanyDelete, SVCompanySaveRequest, SVCompanyUpdatedRequest } from "../../services/companies/CompaniesService";

const CompaniesDataContext = createContext();

const CompaniesDataContextProvider = ({ children }) => {
    const [listCompanies, setListCompanies] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companyIdToDelete, setCompanyIdToDelete] = useState(0);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [searchInput, setSearchInput] = useState("");


    const { setSuccessMsg,
        setErrorMsg,
        setLoading,
        messageUser,
        loading,
        authenticate,
        authInfo,
        isAdmin,
    } = useApp();

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedCompany(null)
    };

    const onClickActionEditButton = (params) => {
        console.log('onClickActionEditButton',params)
        setSelectedCompany(params);
        handleOpenModal()
    };

    const handleFormSubmit = async (values) => {
        try {
            const input = {
                ...values
            };
            setLoading(true)
            if (values.id) {
                await SVCompanyUpdatedRequest(input);
                setSuccessMsg("Editado con éxito");

            } else {
                await SVCompanySaveRequest(input);
                setSuccessMsg("Creado con éxito")
            }
            loadCompanies();
            setLoading(false);
            handleCloseModal();
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const loadCompanies = async () => {
        try {
            let responseCompaniesList = await SVCompaniesRequest(authInfo.id, authInfo.userRole.id, searchInput);
            const companies = responseCompaniesList.content.map(companyData => {
                return new CompanyDto(
                    companyData.factor_id,
                    companyData.name
                );
            });
            setListCompanies(companies);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            const errorMessage = getErrorMessageFromError(error);
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    }

    const openDeleteConfirmation = (companyId) => {
        setCompanyIdToDelete(companyId);
        setShowDeleteConfirmation(true);
    };

    const closeDeleteConfirmation = () => {
        setCompanyIdToDelete(null);
        setShowDeleteConfirmation(false);
    };

    const handleDeleteConfirmed = async () => {
        closeDeleteConfirmation();
        if (companyIdToDelete) {
            await handleDelete(companyIdToDelete);
        }
    };

    const handleDelete = async (companyId) => {
        try {
            setLoading(true)
            await SVCompanyDelete(companyId);
            setSuccessMsg("Eliminado con éxito")
            loadCompanies();
            setLoading(false)
        } catch (error) {
            let errorMessage = getErrorMessageFromError(error);
            if (!errorMessage) {
                errorMessage = "Error desconocido al procesar la solicitud";
            }
            setErrorMsg(errorMessage);
            setLoading(false);
        }
    };

    return (
        <CompaniesDataContext.Provider value={{
            listCompanies,
            loadCompanies,
            loading,
            messageUser,
            handleOpenModal,
            handleCloseModal,
            isModalOpen,
            onClickActionEditButton,
            setSelectedCompany,
            selectedCompany,
            showDeleteConfirmation,
            openDeleteConfirmation,
            closeDeleteConfirmation,
            handleDeleteConfirmed,
            handleFormSubmit,
            handleDelete,
            searchInput,
            setSearchInput
        }}>
            {children}
        </CompaniesDataContext.Provider>
    )
}

export { CompaniesDataContext, CompaniesDataContextProvider };
