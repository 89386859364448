
import { Menu, MenuItem, ProSidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Box, Stack, Typography, useTheme, Button, Tooltip } from "@mui/material";

import MessageManager from "../../components/utils/MessageManager";
import { useApp } from "../../hooks/useApp";
import { tokens } from "../../theme";
import { CONSTANT } from "../../utils/Constant";

import "react-pro-sidebar/dist/css/styles.css";
import { useSidebarData } from "./SidebarDataContext";

import { Logo, HomeIcon, UserIcon, DepartmentIcon, ClientIcon, NotificacionIcon, LogoutIcon, TemplateIcon } from "../../components/svgicons/SvgIcons";

const Item = ({ title, to, icon, selected, setSelected, alert = false }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const alertColor = colors?.orange?.[500];

  return (
    <>
    {selected === title ? (
      <Box
        width= "235px"
        height= "46px"
        backgroundColor= {colors.primary[500]}
        boxShadow= "0px 4px 4px rgba(0, 0, 0, 0.25)"
        borderRadius= "10px"
      >      
        <MenuItem
          active={selected === title}
          fontWeight= "bold"
          onClick={() => setSelected(title)}
          icon={icon}
        >
          <Stack direction="row" gap={3}>
            <Typography 
              variant="h5" 
              fontWeight="bold"
              color= {colors.secondary[800]}
            >{title}</Typography>
            {alert &&
              <Tooltip title={CONSTANT.LABEL_NEW_MESSAGE}>
                <NotificacionIcon sx={{ fontSize: "0.9rem", width: 16, color: colors.secondary[800] }} />
              </Tooltip>
            }
          </Stack>

          <Link to={to} />
        </MenuItem>
      </Box>
    ):(
        <MenuItem
          active={selected === title}
          color= {colors.grey[900]}
          onClick={() => setSelected(title)}
          icon={icon}
        >
          <Stack direction="row" gap={3}>
            <Typography             
              variant="h5" 
              fontWeight="bold"
              color= {colors.grey[900]}
            >{title}</Typography>
            {alert &&
              <Tooltip title={CONSTANT.LABEL_NEW_MESSAGE}>
                <NotificacionIcon sx={{ fontSize: "0.9rem", width: 16, color: alertColor }} />
              </Tooltip>
            }
          </Stack>
    
          <Link to={to} />
        </MenuItem>
    )}
    </>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {
    errorMsg,
    successMsg,
    resetErrorMsg,
    resetSuccessMsg,
    authInfo,
    logoutProvider
  } = useApp();

  const {
    isCollapsed,
    newMessageClient,
    selected,
    setSelected,
  } = useSidebarData();

  const handleLogout = () => {
    // Lógica para cerrar sesión
    logoutProvider()
  };

  return (
    <>
      <Box
        sx={{
          "& .pro-sidebar": {
            color: `${colors.grey[900]} !important`,
            fontWeight: `bold  !important`,
            minWidth: `320px !important`
          },
          "& .pro-sidebar-inner": {
            background: `${colors.grey[100]} !important`,
          },
          "& .pro-icon-wrapper": {
            backgroundColor: "transparent !important",
            fontWeight:"bold !important"
          },
          "& .pro-inner-item:hover": {
            color: `${colors.primary[500]} !important`,
          },
          "& .pro-menu-item.active": {
            color: `${colors.grey[300]} !important`,
          },
        }}
      >
        <ProSidebar collapsed={isCollapsed}>
          <Stack
            direction="column"
            justifyContent="space-between"
            minHeight="100%"
            spacing={2}
          >
            <Menu iconShape="square">
              <Box
                display="flex"
                alignItems="center"
                marginLeft="15px" 
                marginTop="15px"
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: '50%',
                      backgroundColor: colors.grey[100],
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)"  
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Logo sx={{ width: "98px", height: "98px", marginLeft: "5px" }}/>
                  </Stack>
                </Stack>
                <Typography 
                  display="flex"
                  marginLeft="20px"
                  variant="h4" 
                  fontWeight="bold"
                  color= {colors.grey[900]}
                >
                  Municipalidad 
                  <br/>
                  de Liberia
                </Typography>       
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop="20px"     
                marginBottom="20px"               
              >

                <Typography 
                  variant="h1" 
                  fontWeight="bold"
                  color= {colors.grey[900]}
                >
                  CRM
                </Typography>
              
              </Box>

              <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                <Item
                  title={CONSTANT.LABEL_DASHBOARD}
                  to="/dashboard/home"
                  icon={CONSTANT.LABEL_DASHBOARD === selected ? (<HomeIcon sx={{  width: 25, fontWeight:"bold", color: colors.secondary[800] }}/>) : (<HomeIcon sx={{ width: 25, fontWeight:"bold", color: colors.primary[900] }}/>)}
                  selected={selected}
                  setSelected={setSelected}
                />
                {authInfo?.userRole?.id === 1 && (
                  <Box>
                    <Item
                      title={CONSTANT.LABEL_USERS}
                      to="/dashboard/users"
                      icon={ CONSTANT.LABEL_USERS === selected ? (<UserIcon sx={{  width: 25, color: colors.secondary[800], fontWeight: "bold" }}/>) : (<UserIcon sx={{ width: 25, fontWeight:"bold", color: colors.grey[900] }}/>)}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={CONSTANT.LABEL_COMPANIES}
                      to="/dashboard/companies"
                      icon={CONSTANT.LABEL_COMPANIES === selected ? (<DepartmentIcon sx={{ width: 25, fontWeight:"bold", color: colors.secondary[800] }}/>) : (<DepartmentIcon sx={{ width: 25, fontWeight:"bold", color: colors.grey[900] }}/>)}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={CONSTANT.LABEL_TEMPLATE_TWILIO}
                      to="/dashboard/template/whatsapp/twilio"
                      icon={CONSTANT.LABEL_COMPANIES === selected ? (<TemplateIcon sx={{ width: 25, fontWeight:"bold", color: colors.secondary[800] }}/>) : (<TemplateIcon sx={{ width: 25, fontWeight:"bold", color: colors.grey[900] }}/>)}
                      selected={selected}
                      setSelected={setSelected}
                    />
                    <Item
                      title={CONSTANT.LABEL_TEMPLATE_QUICK_RESPONSE}
                      to="/dashboard/template/whatsapp/quickresponse"
                      icon={CONSTANT.LABEL_COMPANIES === selected ? (<TemplateIcon sx={{ width: 25, fontWeight:"bold", color: colors.secondary[800] }}/>) : (<TemplateIcon sx={{ width: 25, fontWeight:"bold", color: colors.grey[900] }}/>)}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </Box>
                )}
                <Item
                  title={CONSTANT.LABEL_CLIENTS}
                  to="/dashboard/clients"
                  icon={CONSTANT.LABEL_CLIENTS === selected ? (<ClientIcon sx={{ width: 25, fontWeight:"bold", color: colors.secondary[800] }}/>) : (<ClientIcon sx={{ width: 25, fontWeight:"bold", color: colors.grey[900] }}/>)}
                  selected={selected}
                  setSelected={setSelected}
                  alert={newMessageClient}
                />
              </Box>
            </Menu>
            <Stack            
              direction="column"
              alignItems="center"
            >
              <Stack              
                display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"  
                marginBottom="20px" 
              > 
                <Typography 
                  variant="h5" 
                  fontWeight="bold"
                  color= {colors.grey[900]}
                >
                  {authInfo.name}
                </Typography>
                <Typography 
                  variant="h5" 
                  color= {colors.grey[900]}
                >
                  {authInfo.username}
                </Typography>                
              </Stack>

              <Stack              
                display="flex"
                justifyContent="center"
                alignItems="center" 
                marginBottom="40px"
              > 
                <Button 
                  variant="contained"  
                  color="grey"
                  onClick={handleLogout}
                  sx={{ width: "40px !important", minWidth:"40px !important", maxWidth:"40px !important",
                  height:"40px !important", minWheight:"40px !important", maxWheight:"40px !important"}}
                >
                  <LogoutIcon sx={{ width: 25, color: colors.grey[900] }}/>
                </Button>
              </Stack>
              <Stack>
                {/* Espacio en blanco para que respete el margin bottom del objecto de arriba */}
              </Stack>
            </Stack>
          </Stack>
        </ProSidebar>
      </Box>
      {errorMsg && (
        <MessageManager
          type={CONSTANT.ERROR_MSG}
          msg={errorMsg}
          details=''
          callback={resetErrorMsg}
        />
      )}
      {successMsg && (
        <MessageManager
          type={CONSTANT.SUCCESS_MSG}
          msg={successMsg}
          details=''
          callback={resetSuccessMsg}
        />
      )}
    </>

  );
};

export default Sidebar;
