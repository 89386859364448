import { useEffect } from "react";
import Swal from "sweetalert2";
import { properties } from "../../utils/Properties_es";
import { CONSTANT } from "../../utils/Constant";

const MessageManager = ({ type, msg, details, callback }) => {
  useEffect(() => {
    showMessage();
  }, []);

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      text: details,
      icon: type === CONSTANT.ERROR_MSG ? "error" : "success",
      confirmButtonText: properties.label_button_ok_title,
      confirmButtonColor: "#4cceac",
    }).then(() => callback && callback());
  };

  return <></>;
};

export default MessageManager;
