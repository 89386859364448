import { Suspense } from 'react';
import { Await, useLoaderData, useOutlet } from 'react-router-dom';
import { AppProvider } from '../../contexts/AppContext';
import Loading from '../utils/Loading';
import { properties } from '../../utils/Properties_es';

export const AuthLayout = () => {
  const outlet = useOutlet();

  const { authPromise } = useLoaderData();

  return (
    <Suspense fallback={<Loading title={properties.label_loading_title} />}>
      <Await
        resolve={authPromise}
        children={authObject => <AppProvider authData={authObject}>{outlet}</AppProvider>}
      />
    </Suspense>
  );
};
