export const properties = {
  /** General */

  /** footer */
  label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  label_page_404_title: "Oops!",
  label_page_404_subtitle: "Página no encontrada",

  /** Loading */
  label_loading_title: "Cargando",

  /** Login */
  label_login_title: "Municipalidad de Liberia CRM",

  /** Home */
  label_home_title: "Municipalidad de Liberia CRM",

  /** General error labels in process */
  label_request_connection: "Falla en solicitud al servidor",
  label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

  /** General label */
  label_yes: "Si",
  label_not: "No",
  label_add_subtitle: "Ingrese los datos indicados",
  label_update_subtitle: "Modifique los datos indicados",
  label_code: "Código",
  label_codetfa: "Código de validación",
  label_phone: "Teléfono",
  label_url: "Url",
  label_category: "Categoría",
  label_name: "Nombre",
  label_lastname: "Apellido",
  label_state: "Estado",
  label_role: "Rol",
  label_createat: "Fecha de creación",
  label_initdate: "Fecha inicio",
  label_specificdate: "Fecha Especifica",
  label_enddate: "Fecha final",
  label_email: "Correo electrónico",
  label_password: "Contraseña",
  label_passwordrepeat: "Repita contraseña",
  label_id: "ID",
  label_description: "Descripción",
  label_price: "Precio",
  label_price2: "Precio:",
  label_subtotal: "SubTotal",
  label_total: "Total",
  label_taxes: "Impuesto",
  label_tax: "ITBIS (18%)",
  label_department: "Departamento",
  label_generationtime: "Tiempo generación",
  label_comment: "Comentario",
  label_free: "Gratis",
  label_zero: 0,
  label_select: "Seleccione",
  label_select_other: "Otro",
  label_not_apply: "No aplica",
  label_time: "Duración ",
  label_observacion: "Observación",
  label_terms: "Aceptar términos y condiciones",
  label_note: "Nota",
  label_department_con_title: "Contabilidad",
  label_department_ope_title: "Operaciones",
  label_department_sec_title: "Servicio al contribuyente",
  label_department_all_title: "Todos",
  label_cardnumber: "Número tarjeta",
  label_exp: "Fecha expiración",
  label_cvv: "Cvv",
  label_TypeCard: "Tipo de tarjeta",
  label_cardname: "Nombre titular",
  label_certificate: "Certificado",
  label_account: "Cuenta",
  label_to: "Dirigido a",
  label_invoicetype: "Tipo de factura",
  label_language: "Idioma",
  label_not_apply_title: "No aplica",
  label_card_title: "TARJETA",
  /** General error labels in forms */
  label_field_required: "Campo requerido",
  label_field_required_format: "Campo requerido o formato inválido",
  label_field_format: "Formato invalido",
  label_field_maxlenght: (lenght) =>
    `Longitud máxima permitida (${lenght})`,
  label_field_minlenght: (lenght) =>
    `Longitud minima permitida (${lenght})`,

  /** General button labels */
  label_button_accept_title: "Aceptar",
  label_button_ok_title: "Cerrar información",
  label_button_cancel_title: "Cancelar",
  label_button_save_title: "Registrar",
  label_button_update_title: "Editar",
  label_button_delete_title: "Eliminar",
  label_button_back_title: "Atras",
  label_button_back2_title: "Volver",
  label_button_add_title: "Agregar",
  label_button_search_title: "Buscar",
  label_button_clear_title: "Limpiar",
  label_button_login_title: "Iniciar Sesión",
  label_button_close_title: "Cerrar",
  label_button_view_title: "Ver",
  label_button_exit_title: "Salir",
  label_button_forgotpass_title: "Recuperar contraseña",
  label_button_updatepass_title: "Actualizar contraseña",
  label_button_payment_title: "Procesar pago",
  /** General Modal */
  label_modal_title: "Terminos y Condiciones",

  /** login */
  label_loginPortal_title: "Portal",
  label_loginContribuyente_title: "Ejecutivo",
  label_login_forget_password_title: "¿Olvidaste tu contraseña?",

  /** login success*/
  label_login_twofactor_success:
    "Código de validación enviado al correo",

  /** login error*/
  label_login_twofactor_error:
    "Error en proceso de enviar código de validación",
  label_login_error: "Correo o contraseña inválida",
  label_login_error2:
    "Agente no tiene permiso de loguearse en este sitio",
  label_login_error3: "El agente no está activo",
  label_login_general_error:
    "Error en proceso de ingresar al portal administrativo",
  label_login_logout_error: "Error en proceso de cerrar sesion",

  /** RecoveryPassword */
  label_recoverypass_title: "Recuperar Contraseña",
  label_recoverypass_back_title: "Regresar a login",
  /** ForgotPassword success*/
  label_recoverypass_general_sucess:
    "El correo de recuperación a sido enviado",
  /** ForgotPassword error*/
  label_recoverypass_general_error:
    "Error en proceso de recuperar contraseña",

  /** ForgotPassword */
  label_forgotpass_title: "Cambiar Contraseña",
  /** ForgotPassword success*/
  label_forgotpass_general_sucess:
    "El cambio de contraseña se completo",
  /** ForgotPassword error*/
  label_forgotpass_different_password_error:
    "Las contraseñas no coinciden",
  label_forgotpass_general_error:
    "Error en proceso de cambiar contraseña",

  /** ActiveRegister */
  label_activeregister_title: "Activación Agente",
  /** ActiveRegister success*/
  label_activeregister_general_sucess:
    "La activación del agente se completó",
  label_activeregister_general_sucess2:
    "La activación del agente se completó correctamente.",
  /** ActiveRegister error*/
  label_activeregister_general_error:
    "Error en proceso de activar agente",

}
