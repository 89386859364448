
import { Stack, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";

const Footer = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
        <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        >
            <Typography 
                variant="h5" 
                color= {colors.grey[900]}
            >
                ¡Avanzando con pasos firmes...hacia una gestión municipal moderna!
            </Typography>
            <Typography 
                variant="h5" 
                color= {colors.grey[900]}
            >
                Powered by <a href="https://bitcode-enterprise.com" target="_blank" rel="noreferrer">BitCode Enterprise</a>
            </Typography>                
        </Stack>
    </>

  );
};

export default Footer;
