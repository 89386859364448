import { privateFetch } from "../../utils/CustomFetch";
import { CONSTANT } from "../../utils/Constant";

export const SVCompaniesRequest = async (userId, role, searchInput ) => {
    let responseData = []
    await privateFetch.get(`maintenance/company?userId=${userId}&role=${role}&page=0&size=100&query=${searchInput}`, {
        headers: {
            ...CONSTANT.HEADER_TYPE,
        },
    })
    .then(async (response)=> {
        responseData = await response.data
    }).catch((error)=> {
        throw new Error (error)
    });

    return responseData
}

export const SVCompanySaveRequest = async (data) => {
    try {
        const response = await privateFetch.post('maintenance/company', data, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        return response.data;   
    } catch (error) {
        throw error;
    }
}

export const SVCompanyUpdatedRequest = async (data) => {
    console.log(data);
    try {
        const response = await privateFetch.put(`maintenance/company/${data.id}`,
        {
            'name': data.name
        }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export const SVCompanyDelete = async (id) => {
    try {
        const response = await privateFetch.delete(`maintenance/company/${id}`, {
            headers: {
                ...CONSTANT.HEADER_TYPE,
            },
        });
        // console.log('RESPONSE:',response);
        if ( response.status === 200) {
            // console.log('SUCESS', response.data);
            return response.data.message;
        } else {
            throw new Error("Error al eliminar.");
        }
    } catch (error) {
        throw error;
    }
}
